// clever reach newsletter form styles

.cr_form {

	.cr_button {
		@include button();
		@include font(base);
		border-color: currentColor;
		border-style: solid;
		border-width: $buttonBorderSize;
		padding: $inputPadding;
		text-align: center;

		&:disabled,
		&.js-disabled {
			cursor: default;
			opacity: $opacityInputDisabled;
			pointer-events: none;
		}
	}
	input {
		@include font(base);
		background-color: $colorBgInput;
		color: $colorInput;
		border-color: currentColor;
		border-style: solid;
		border-width: $buttonBorderSize;
		margin-right: $base3;

		&:focus {
			outline-color: currentColor;
			outline-style: solid;
			outline-width: $inputBorderSize;
		}
	}
	label {
		@include font(base);
		font-family: $fontFamilyBold;

		border-color: transparent;
		border-style: solid;
		border-width: $inputBorderSize 0;
		margin-right: $base3;
		padding: $inputPadding 0;
		position: relative;
	}
	.cr_ipe_item {
		padding-bottom: $base;
	}
	.clever_form_error {
		color: red;
	}
}
