// special styles for bahnhof der wissenschaften page

$buttonSize: 18vw;
$buttonSizeHover: 20vw;

$buttonSizeBreak: 11vw;
$buttonSizeHoverBreak: 13vw;

.bahnhof {
	&__intro {
		position: relative;
		background-color: transparent;
		background-repeat: no-repeat;
		background-size: cover;


		&--all {
			background-image: url(/assets/bahnhof/bahnhof_intro-mobile.png);
			width: 100%;
			padding-bottom: 141%;
			margin-left: 0;
			@include media($sidebarBreakpoint) {
				background-image: url(/assets/bahnhof/bahnhof_intro.png);
				width: 136%;
				padding-bottom: 73%;
				margin-left: -122px;
			}
		}
		&--kohlenstoffatom {
			background-image: url(/assets/bahnhof/bahnhof_kohlenstoffatom.png);
			width: 136%;
			padding-bottom: 73%;
			margin-left: -122px;
		}
		&--eisbohrkern {
			background-image: url(/assets/bahnhof/bahnhof_eisbohrkern.png);
			width: 136%;
			padding-bottom: 73%;
			margin-left: -122px;
		}
		&--wettersatellit {
			background-image: url(/assets/bahnhof/bahnhof_wettersatellit.png);
			width: 136%;
			padding-bottom: 73%;
			margin-left: -122px;
		}
	}

	&__button {
		transform: translate(5rem, -5rem);
		text-align: center;
		text-decoration: none;
		position: absolute;
		top: 0;
		left: 0;
		width: $buttonSize;
		height: $buttonSize;
		@include media($sidebarBreakpoint) {
			width: $buttonSizeBreak;
			height: $buttonSizeBreak;
		}
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		mix-blend-mode: multiply;

		&--close {
			top: 7%;
			left: 70%;
		}

		&--element1 {
			top: 89%;
			left: 46%;
			@include media($sidebarBreakpoint) {
				top: 71%;
				left: 3%;
			}

		}

		&--element2 {
			top: 37%;
			left: 28%;
			@include media($sidebarBreakpoint) {
				top: 76%;
				left: 58%;
			}
		}

		&--element3 {
			top: 1%;
			left: 21%;
			@include media($sidebarBreakpoint) {
				top: 11%;
				left: 65%;
			}

		}

		&:after {
			@include app-circle($buttonSize);
			transition: border-radius 0.4s, background-color 0.4s, line-height 0.4s, width 0.4s, height 0.4s;
			@include media($sidebarBreakpoint) {
				@include app-circle($buttonSizeBreak);
				transition: border-radius 0.4s, background-color 0.4s, line-height 0.4s, width 0.4s, height 0.4s;
			}
		}

		&:hover:after {
			@include app-circle($buttonSizeHover);
			transition: border-radius 0.4s, background-color 0.4s, line-height 0.4s, width 0.4s, height 0.4s;

			@include media($sidebarBreakpoint) {
				@include app-circle($buttonSizeHoverBreak);
				transition: border-radius 0.4s, background-color 0.4s, line-height 0.4s, width 0.4s, height 0.4s;
			}
			background-color: $colorHighlightHover;
		}
	}

	&__buttonLabel {
		font-family: $fontFamilyAlt;
		@include font(metaBigger);
		display: inline-block;
		color: white;
		position: relative;
		z-index: 1;
		transition: opacity 0.4s;
		width: $buttonSize;
		height: $buttonSize;
		line-height: $buttonSize;
		@include media($sidebarBreakpoint) {
			width: $buttonSizeBreak;
			height: $buttonSizeBreak;
			line-height: $buttonSizeBreak;
		}

		[data-type='svg'] {
			top: 3px;
		}
	}
}
.js-pageBahnhof {
	.sidebar {
		order: 3
	}
}

