
@mixin visuallyHidden($on: true) {
	@if ($on) {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
		overflow: hidden;
		clip: rect(0 0 0 0);
	} @else {
		position: static;
		width: auto;
		height: auto;
		margin: auto;
		overflow: visible;
		clip: none;
	}
}
