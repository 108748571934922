.imageHeader {
	margin-bottom: $gridRow;

	&__headline {
		text-align: left;
		margin-bottom: $gridRow;
		left: $mobilePadding;
		font-size: pxToRem(28px);
		color: $colorBgAlternative;
		line-height: 1;
		z-index: 1;
		font-weight: normal;
		margin-left: $mobilePadding;
		margin-right: $mobilePadding;

		@include media($midBreakpoint) {
			text-align: center;
			margin: 0 auto;
			font-size: pxToRem(56px);
			width: gridColumns(9, 11);
			margin-bottom: $gridRow;
		}
	}

	&__img {
		width: 100%;
	}

	&__image {
		//margin-top: $gridRow;
	}

	&__imageContainer {
		//
		position: relative;
	}

	&__overlay {
		background-image: linear-gradient(180deg, #0400ff 28%, rgba(4, 0, 255, 0));
		bottom: 30px;
		left: 30px;
		mix-blend-mode: multiply;
		opacity: 0;
		position: absolute;
		right: 30px;
		top: 30px;
		transition: 0.5s ease;

		.imageHeader__imageContainer:hover & {
			opacity: 1;
		}
	}

	&__overlayDescription {
		color: #fff;
		position: absolute;
		top: 50%;
		left: 50%;
		opacity: 0;
		transition: 0.5s ease;
		transform: translate(-50%, -50%);
		text-align: center;

		.imageHeader__imageContainer:hover & {
			opacity: 1;
		}
	}
}
