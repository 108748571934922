.newsletterLink {
	align-items: center;
	bottom: 0;
	display: flex;
	background-color: transparentize($colorHighlight, 0.25);
	mix-blend-mode: multiply;
	height: $base4;
	border-radius: $base2;
	max-width: $base4;
	padding: 0 $base 0 $base * 1.1;
	position: absolute;
	pointer-events: all;
	right: $base2;
	transform: rotateX(90deg);
	transition:
		background-color $hoverDuration,
		max-width $newsletterLinkExpandDuration,
		transform $newsletterLinkScaleDuration,
		padding $newsletterLinkExpandDuration cubic-bezier(0, 0.88, 0.43, 1.13);
	overflow: hidden;

	// @include media($smallLayout) {
	// 	bottom: $headerSpacingMedium;
	// 	right: $containerPaddingSmall;
	// }

	// @include media($midBreakpoint) {
	// 	right: $base * 2.4;
	// 	bottom: $headerSpacingLarge;
	// }

	// @include media($largeLayout) {
	// 	right: $base * 3.5;
	// }

	&.js-hidden {
		display: none;
	}

	&.js-initialAnimation {
		transform: rotateX(0deg);

		@include onHover() {
			background-color: $colorHighlight;
			max-width: 50%;
			padding: 0 $base * 1.7;

			@include media($midBreakpoint) {
				max-width: 25%;
			}
		}
	}

	&.js-expanded {
		max-width: 50%;
		padding: 0 $base * 1.7;

		@include media($midBreakpoint) {
			max-width: 25%;
		}
	}


	&__icon {
		@include fadeOut();
		align-items: center;
		display: inline-flex;
		color: $colorInverted;
		height: $base2;

		span[data-type="svg"] {
			stroke: currentColor;
		}


		.newsletterLink.js-initialAnimation & {
			@include fadeIn($newsletterLinkFadeDuration, $easeBase, $newsletterLinkScaleDuration * 0.5);
		}
	}

	&__label {
		@include fadeOut($newsletterLinkFadeDuration);
		@include font(base);
		color: $colorInverted;
		margin-left: $base1;
		vertical-align: text-bottom;
		white-space: nowrap;

		.newsletterLink.js-expanded & {
			@include fadeIn();
		}

		@include onHover('.newsletterLink.js-initialAnimation', '&') {
			@include fadeIn();
		}
	}

	&__link {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		text-decoration: none;
	}
}
