@mixin ease($properties, $duration, $easing: '', $delay: '') {
	$i: 0;
	$result: '';
	@each $property in $properties {
		@if ($i > 0) {
			$result: $result + ', ';
		}
		$result: $result + $property + ' ' + $duration + ' ' + $easing + ' ' + $delay;
		$i: $i + 1;
	}
	transition: unquote($result);
}


$easeLinear: linear;
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94) !default;
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1) !default;
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1) !default;
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1) !default;

