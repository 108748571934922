.videoPlayer {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	&--bg {
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: -1;
	}


	&__embed {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;


		> iframe,
		> video {
			border: 0;
			height: 100%;
			width: 100%;
		}
	}
}
