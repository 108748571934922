.langMenu {
	&__link {
		@include app-link;
	}
	&__item {
		display: inline;
		@include media($midBreakpoint) {
			display: block;
		}
	}
}
