$colorBase: #0a0a19;
$colorBgBase: #fffaf5;
$colorInverted: $colorBgBase;
$colorBgInverted: $colorBase;

$colorAlternative: #fff;
$colorBgAlternative2: #f1f1f1;
$colorBgAlternative: #00376c;
//$colorBgAlternativeTrans: #f1f1f1;f9f4ef
$colorBgAlternativeTrans: rgba(241, 241, 241, 0.8);
$colorBgOverlay: rgba(230, 225, 221, 0.8);
$colorBgContent: #f9f4ef;
$colorBgSidebar: rgba(0, 0, 0, 0.05);

$colorHighlight: #0200ff;
$colorHighlightHover: darken($colorHighlight, 10%);

$colorBgModal: #CCCCCC;

$colorInput: $colorBase;
$colorBgInput: transparent;
$colorInputBorder: $colorBase;
$colorError: #FF0000;

$colorBgModalCover: transparentize($colorBgInverted, 0.4);
$colorNotActive: transparentize($colorBgInverted, 0.55);
