.slideshowNavigation {
	&__arrows {
		position: absolute;
		z-index: 1;
	}

	&__next, &__prev {
		position: fixed;
		top: 45%;
		background: $colorHighlight;
		color: white;
		border-radius: 50%;
		width: $buttonSize;
		height: $buttonSize;
		border:1px solid $colorHighlight;
		&:focus {
			border-color:white;
		}
	}

	&__next {
		right: $gridUnit;
		[data-type="svg"] {
			transform: scaleX(-1);
		}
	}

	&__prev {
		left: $gridUnit;
	}

}
