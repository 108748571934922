.htmlcode {
	@include font(base);
	padding-bottom: $gridUnit * 2;
	padding-left: $mobilePadding;
	padding-right: $mobilePadding;

	@include media($midBreakpoint) {
		padding-left: gridColumns(1, 11);
		padding-right: gridColumns(1, 11);
		.sidebarredContainer & {
			padding-left: gridColumns(1, 8);
			padding-right: gridColumns(1, 8);
		}
	}
	&--nopadding {
		padding: 0;
	}
}
