.slideshow {
	display: none;
	position: relative;
	&__items {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		touch-action: pan-y;
		width: 100%;
	}
	&__itemsContainer {
		display: flex;
		width: 100%;
	}
	.mediaModal & {
		display: block;
		height: 100%;
		&__itemsContainer {
			height: 100%;
		}
	}
}
