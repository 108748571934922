@mixin app-circle($size, $color: $colorHighlight, $pseudo: true, $mix: true) {
	@if $pseudo {
		content: '';
		position: absolute;
		display: inline-block;
	}
	width: $size;
	height: $size;
	border-radius: $size * 0.5;
	background-color: $color;
	@if $mix {
		mix-blend-mode: multiply;
		transition: background-color 0.2s;
	}
}
