.blockContactForm {
	padding-top: $base4;
	padding-bottom: $base4;
	overflow: hidden;

	&__copytext {
		margin-bottom: $base4;
		//@include font(base);
	}

	&__formContent {
		order: 2;
		width: 100%;
	}

	&__header {
		margin-bottom: $base4;
		//@include font(headerlight);
	}

	&__message {
		display: block;
		width: 100%;
	}
}
