@use "sass:math";

$gridUnit: pxToRem(14);
$gridRow: $gridUnit * 4;
$numCols: 14;
$maxContentWidth: 1920px;

@function gridColumns($n, $from: $numCols) {
	@return math.div($n * 100%, $from);
}

@function gridBoundaries($top, $leftCols, $bottom, $rightCols, $from: $numCols) {
	@return $top gridColumns($leftCols, $from) $bottom gridColumns($rightCols, $from);
}




